




export const navList = [
    {
        value:"序号",
        state:"0",
        num:0
    },{
        value:"姓名",
        state:"1",
        num:0
    },{
        value:"最近更新",
        state:"0",
        num:0
    },{
        value:"代取货",
        state:"0",
        num:0
    },{
        value:"待送达",
        state:"0",
        num:0
    },{
        value:"已完成",
        state:"0",
        num:0
    },
]


