import {disPathSuc} from "@/Api/ShopAdmin";
import Storage from "@/util/Storage"

export function getDisPath() {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve( disPathSuc({ userId,token }).then(res=>{
        return res.data
    }) )
}
