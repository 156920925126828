

import { Module,VuexModule,getModule,Mutation,Action } from "vuex-module-decorators"
import store from "@/store/index"
import DeliverySucStoreType from "@/views/Dispatching/DeliverySuc/Store/indexType";

@Module({
    name:"DeliverySuc",
    store,
    dynamic: true,
})
export default class DeliverySuc extends VuexModule implements DeliverySucStoreType{

    DeliverySucList: any[] = [];
    get getDeliverySucList(){ return this.DeliverySucList }
    @Mutation
    SetDeliverySucList(data: any[]): void {
        this.DeliverySucList = data
    }

}

export const DeliverySucStore = getModule( DeliverySuc )
